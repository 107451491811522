import { gql, useLazyQuery } from '@apollo/client';
import { Box, Flex, Image, Rating } from '@energiebespaarders/symbols';
import { Larger } from '@energiebespaarders/symbols/helpers';
import Head from 'next/head';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useIsInitializing } from '~/hooks/useIsInitializing';
import delimit from '../../utils/delimit';
import type { getKlantenVertellenScore } from '../../types/generated/getKlantenVertellenScore';

export const GET_KLANTENVERTELLEN_REVIEWS = gql`
  query getKlantenVertellenScore {
    getKlantenVertellenScore {
      averageRating
      numberReviews
      last12MonthAverageRating
      last12MonthNumberReviews
      percentageRecommendation
    }
  }
`;

const StyledWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: inline-block;
  width: 100px;
  height: 100px;
`;

const StyledBackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
`;

const StyledInt = styled(Box)`
  position: relative;
  font-size: 1.75rem;
  font-weight: 500;
`;

interface KlantenVertellenProps {
  layout?: 'vertical' | 'horizontal';
  scale?: number;
  transparent?: boolean;
}

const KlantenVertellen: React.FC<KlantenVertellenProps> = ({ layout = 'vertical', scale = 1 }) => {
  const [fetchScore, { data, loading, error }] = useLazyQuery<getKlantenVertellenScore>(
    GET_KLANTENVERTELLEN_REVIEWS,
    { fetchPolicy: 'cache-first' },
  );
  const ratingValue = data?.getKlantenVertellenScore?.last12MonthAverageRating ?? 9;
  const ratingCount = data?.getKlantenVertellenScore?.numberReviews ?? 100;

  // Can't query anything before app is initialized: another ME may get initialized
  const { isInitializing } = useIsInitializing();

  useEffect(() => {
    if (!data && !loading && !error && !isInitializing) void fetchScore();
  }, [fetchScore, data, loading, error, isInitializing]);

  const jsonLdData = useMemo(() => {
    if (!ratingValue || !ratingCount) return null;

    return {
      '@context': 'https://schema.org',
      '@type': 'AggregateRating',
      itemReviewed: {
        '@type': 'Organization',
        name: 'Energiebespaarders',
        url: 'https://energiebespaarders.nl',
      },
      ratingValue: ratingValue,
      ratingCount: ratingCount,
      bestRating: 10,
      worstRating: 1,
    };
  }, [ratingValue, ratingCount]);

  return (
    <>
      <Head>
        {jsonLdData && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
          />
        )}
      </Head>

      <a
        title="Lees onze reviews op KlantenVertellen.nl"
        href="https://www.klantenvertellen.nl/reviews/1039761/de_energiebespaarders"
        target="_blank"
        rel="noopener noreferrer"
        className="image-link"
        style={{
          display: 'inline-block',
          color: 'black',
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
        }}
      >
        <Flex
          style={{ display: 'inline-flex' }}
          flexWrap={layout === 'vertical' ? 'wrap' : 'nowrap'}
        >
          <Box width={layout === 'vertical' ? 1 : undefined} style={{ textAlign: 'center' }}>
            <StyledWrapper>
              <StyledBackgroundImage src="/img/klantenvertellen.svg" alt="KlantenVertellen" />
              <Flex justifyContent="center" flexDirection="column" width="100%" height="100%">
                <StyledInt>{delimit(ratingValue, 1)}</StyledInt>
              </Flex>
            </StyledWrapper>
            {layout === 'vertical' && <Box mb={1}>{ratingCount} beoordelingen</Box>}

            <div style={{ transform: `scale(${scale})` }}>
              <Rating rating={ratingValue / 2} useHalfStars readOnly />
            </div>
          </Box>

          <Box width={layout === 'vertical' ? 1 : undefined}>
            <div>
              <Larger>
                klanten
                <strong>vertellen</strong>
              </Larger>
            </div>
            {layout === 'horizontal' && <Box mb={1}>{ratingCount} beoordelingen</Box>}
          </Box>
        </Flex>
      </a>
    </>
  );
};

export default KlantenVertellen;
